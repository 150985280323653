/* eslint-disable import/first */
/* cSpell:disable */
import './imports/vendor';

import { getChannelModule } from './app.module';

import '../src/decorate-title.js';

import './directives/components/actionMenu/actionMenuDir.js';
import './directives/components/alert/alertDir.js';
import './directives/components/amountSearch/amountSearchDir.js';
import './directives/components/attributeList/attributeListDir.js';
import './directives/components/checkImage/checkImageDir.js';
import './directives/components/checkImage/checkImageModal/checkImageCtrl.js';
import './directives/components/dateSearch/dateSearchDir.js';
import './directives/components/differenceFlag/differenceFlagDir.js';
import './directives/components/emptyState/emptyStateDir.js';
import './directives/components/exportOptionBar/exportOptionBarDir.js';
import './directives/components/fixedHeader/fixedHeaderDir.js';
import './directives/components/flexSpinner/flexSpinner.js';
import './directives/components/focusIf/focusIfDir.js';
import './directives/components/nonBlockingSpinner/nonBlockingSpinner.js';
import './directives/components/panel/panelDir.js';
import './directives/components/reportFilter/reportFilterDir.js';
import './directives/components/reportFilter/saveReport/saveReportCtrl.js';
import './directives/components/resizer/resizerDir.js';
import './directives/components/sidebarContainer/sidebarContainerDir.js';
import './directives/components/sortableTable/sortableTableDir.js';
import './directives/components/spinner/spinnerDir.js';
import './directives/components/superUserPanel/superUserPanelDir.js';
import './directives/components/timer/timer.js';
import './directives/components/timer/timerService.js';
import './directives/components/wizard/wizardDir.js';
import './directives/controls/alphaNumericInputDir.js';
import './directives/controls/amountFilter/amountFilter.js';
import './directives/controls/approvalStatus/approvalStatusDir.js';
import './directives/controls/autoCollapse/autoCollpase.js';
import './directives/controls/backDir.js';
import './directives/controls/download/downloadDir.js';
import './directives/controls/dropdownMultiSelect/dropdownMultiSelectDir.js';
import './directives/controls/flipperDir.js';
import './directives/controls/frontDir.js';
import './directives/controls/genericMultiSelectDropdown/genericMultiSelectDropdownDir.js';
import './directives/controls/includeHeader/headerOptionCtrl.js';
import './directives/controls/limitToDir.js';
import './directives/controls/ng-currency.js';
import './directives/controls/ng-phone.js';
import './directives/controls/numericInputDir.js';
import './directives/controls/popOver/popOverDir.js';
import './directives/controls/print/printDir.js';
import './directives/controls/switch/switchDir.js';
import './directives/controls/toggle/toggleDir.js';
import './directives/controls/typeAhead/typeAheadNameDir.js';
import './directives/controls/typeAheadAccount/typeAheadDir.js';
import './directives/controls/typeAheadAchBanks/typeAheadAchBanksDir.js';
import './directives/controls/typeAheadAchCompanies/typeAheadDir.js';
import './directives/controls/typeAheadAchOffsetAccount/typeAheadAchOffsetAccountDir.js';
import './directives/controls/typeAheadAchOffsetAccounts/typeAheadAchOffsetAccountsDir.js';
import './directives/controls/typeAheadAchTaxCodes/typeAheadAchTaxCodesDir.js';
import './directives/controls/typeAheadBeneficiaryBanks/typeAheadBeneficiaryBanksDir.js';
import './directives/controls/typeAheadCompanyAccount/typeAheadCompanyAccountDir.js';
import './directives/controls/typeAheadStates/typeAheadStatesDir.js';
import './directives/utility.js';
import './directives/validationGroupDir.js';

/** **load filters*** */
import './filters/account.js';
import './filters/bank.js';
import './filters/fileSizeFormatter.js';
import './filters/frequency.js';
import './filters/negativeParenthesis.js';
import './filters/orderByNumeric.js';
import './filters/transferStatus.js';
/** **load Controllers*** */
import './shared/footer/footerCtrl.js';
import './shared/header/headerCtrl';
import './shared/resetPassword/controllers/resetPasswordCtrl.js';
/** **login controllers*** */
import './login/controllers/answerSecurityQuestionsCtrl.js';
import './login/controllers/changePasswordCtrl.js';
import './login/controllers/createSecurityQuestionsCtrl.js';
import './login/controllers/forgotPasswordCtrl.js';
import './login/controllers/forgotPasswordEmailConfirmationCtrl.js';
import './login/controllers/lockedOutCtrl.js';
import './login/controllers/loginCtrl.js';
import './login/controllers/registerSecureTokenCtrl.js';
import './login/controllers/termsAndConditionsAcceptCtrl.js';
import './login/controllers/userVerificationCtrl.js';
import './login/controllers/verifySecurityMethodModalCtrl.js';
import './login/controllers/verifySecurityMethodsCtrl.js';

/** **User Controllers*** */
import './user/controllers/changePasswordModalCtrl.js';
import './user/controllers/profileCtrl.js';
/** **Notification Controller*** */
import './notifications/controllers/notificationListCtrl.js';
/** **Dashboard Controllers*** */
import './dashboard/controllers/addWidgetModalCtrl.js';
import './dashboard/controllers/dashboardContainerController.js';
import './dashboard/widgets/controllers/accountDetailModalCtrl.js';
import './dashboard/widgets/controllers/accountGroupAccountListModalCtrl.js';
import './dashboard/widgets/controllers/accountGroupNameModalCtrl.js';
import './dashboard/widgets/controllers/accountGroupsModalCtrl.js';
import './dashboard/widgets/controllers/accountsWidgetCtrl.js';
import './dashboard/widgets/controllers/favouriteReportsCtrl.js';
import './dashboard/widgets/controllers/InformationCenterWidgetCtrl.js';
import './dashboard/widgets/controllers/paymentsPendingApprovalWidgetCtrl.js';
import './dashboard/widgets/controllers/quickLoanPaymentWidgetCtrl.js';
import './dashboard/widgets/controllers/quickTransferWidgetCtrl.js';
import './dashboard/widgets/controllers/resourcesCtrl.js';
import './dashboard/widgets/controllers/stopRequestWidgetCtrl.js';
import './dashboard/widgets/controllers/workArpExceptionsWidgetCtrl.js';
/** **Transfer Controllers*** */
import './payables/internalTransfer/create/controllers/createLoanTransferCtrl.js';
import './payables/internalTransfer/create/controllers/internalTransferCtrl.js';
import './payables/internalTransfer/edit/controllers/editInternalTransferCtrl.js';
import './payables/internalTransfer/list/controllers/changeStatusDialogCtrl.js';
import './payables/internalTransfer/list/controllers/changeTransferCtrl.js';
import './payables/internalTransfer/list/controllers/listCtrl.js';
import './payables/internalTransfer/list/controllers/loanPaymentListCtrl.js';
import './payables/internalTransfer/list/controllers/recurringListCtrl.js';
import './payables/internalTransfer/list/controllers/transferTemplateDetailCtrl.js';
import './payables/internalTransfer/list/controllers/transferTemplateListCtrl.js';
/** **REPORTS DASHBOARD*** */
import './informationReporting/bdiEStatements/controllers/bdiEStatementsCtrl.js';
import './informationReporting/dashboard/controllers/reportsDashboardCtrl.js';
import './informationReporting/dateRangeBalance/controllers/dateRangeBalanceCtrl.js';
import './informationReporting/dateRangeTransaction/controllers/dateRangeTransactionsCtrl.js';
import './informationReporting/dayBalance/controllers/dayBalanceCtrl.js';
import './informationReporting/dayTransaction/controllers/dayTransactionsCtrl.js';
import './informationReporting/edi/controllers/ediCtrl.js';
import './informationReporting/electronicdocuments/controllers/electronicDocumentCtrl.js';
import './informationReporting/researchTransaction/controllers/researchTransactionCtrl.js';
import './informationReporting/wausauStatements/controllers/wausauStatementsCtrl.js';
/** **ACCOUNT LIST*** */
import './accounts/list/controllers/accountDownloadCtrl.js';
import './accounts/list/controllers/accountsListCtrl.js';
import './accounts/transactions/controllers/accountTransactionsCtrl.js';
/** **WIRE*** */
import './payables/wire/beneficiaries/controllers/approveOrRejectBeneficiaryCtrl.js';
import './payables/wire/beneficiaries/controllers/bankDialogCtrl.js';
import './payables/wire/beneficiaries/controllers/beneficiaryDetailDialogCtrl.js';
import './payables/wire/beneficiaries/controllers/beneficiaryListCtrl.js';
import './payables/wire/beneficiaries/controllers/blockRemoveBeneficiaryCtrl.js';
import './payables/wire/beneficiaries/controllers/changeStatusDialogCtrl.js';
import './payables/wire/beneficiaries/controllers/createEditBeneficiaryCtrl.js';
import './payables/wire/beneficiaries/controllers/removeBeneficiaryCtrl.js';
import './payables/wire/components/rateQuote.js';
import './payables/wire/components/rateQuoteDetails.js';
import './payables/wire/fx/controllers/fxWireWizardCtrl.js';
import './payables/wire/fx/controllers/rateQuoteAcceptModalCtrl.js';
import './payables/wire/fx/controllers/rateQuoteExpiredModalCtrl.js';
import './payables/wire/wires/controllers/accountsCtrl.js';
import './payables/wire/wires/controllers/approveOrRejectWireCtrl.js';
import './payables/wire/wires/controllers/beneficiarySelectionCtrl.js';
import './payables/wire/wires/controllers/changeStatusDialogCtrl.js';
import './payables/wire/wires/controllers/createMultipleWireCtrl.js';
import './payables/wire/wires/controllers/createWireCtrl.js';
import './payables/wire/wires/controllers/createWireTemplateCtrl.js';
import './payables/wire/wires/controllers/freeFormBeneficiaryCtrl.js';
import './payables/wire/wires/controllers/readyForQuoteCtrl.js';
import './payables/wire/wires/controllers/recurringWireListCtrl.js';
import './payables/wire/wires/controllers/rejectDliCtrl.js';
import './payables/wire/wires/controllers/removeWireCtrl.js';
import './payables/wire/wires/controllers/removeWireTemplateCtrl.js';
import './payables/wire/wires/controllers/wireDetailsCtrl.js';
import './payables/wire/wires/controllers/wireListCtrl.js';
import './payables/wire/wires/controllers/wireTemplateDetailsCtrl.js';
import './payables/wire/wires/controllers/wireTemplateListCtrl.js';
import './payables/wire/wires/controllers/wireToTemplateDialogCtrl.js';
/** **ACH*** */
import './payables/ach/batch/controllers/achCompaniesModalCtrl.js';
import './payables/ach/batch/controllers/addendaModalCtrl.js';
import './payables/ach/batch/controllers/ApproveRejectBatchCtrl.js';
import './payables/ach/batch/controllers/batchDetailCtrl.js';
import './payables/ach/batch/controllers/batchErrorCtrl.js';
import './payables/ach/batch/controllers/createBatchCtrl.js';
import './payables/ach/batch/controllers/createTaxTemplateCtrl.js';
import './payables/ach/batch/controllers/importRecipientsModalCtrl.js';
import './payables/ach/batch/controllers/offsetAccountsCtrl.js';
import './payables/ach/batch/controllers/recipientsModalCtrl.js';
import './payables/ach/batch/controllers/taxTemplateDetailCtrl.js';
import './payables/ach/batch/controllers/uploadBatchCtrl.js';
import './payables/ach/batch/directives/recipientsTable/bankDialogCtrl.js';
import './payables/ach/batch/directives/recipientsTable/recipientsTable.js';
import './payables/ach/batchList/controllers/batchListCtrl.js';
import './payables/ach/batchList/controllers/taxTemplateListCtrl.js';
import './payables/ach/FileImport/controllers/importLayoutCtrl.js';
import './payables/ach/payments/controllers/achAccountsCtrl.js';
import './payables/ach/payments/controllers/achFileActivityListCtrl.js';
import './payables/ach/payments/controllers/achPaymentListCtrl.js';
import './payables/ach/payments/controllers/approveRejectPaymentCtrl.js';
import './payables/ach/payments/controllers/bulkApproveOrRejectDialogCtrl.js';
import './payables/ach/payments/controllers/cancelPaymentCtrl.js';
import './payables/ach/payments/controllers/createPaymentCtrl.js';
import './payables/ach/payments/controllers/createPaymentDetailCtrl.js';
import './payables/ach/payments/controllers/createPaymentMultipleBatchesCtrl.js';
import './payables/ach/payments/controllers/createPaymentMultipleBatchUploadCtrl.js';
import './payables/ach/payments/controllers/createPaymentMultipleTaxTemplatesCtrl.js';
import './payables/ach/payments/controllers/createPaymentSingleBatchCtrl.js';
import './payables/ach/payments/controllers/createPaymentSingleTaxCtrl.js';
import './payables/ach/payments/controllers/createTaxPaymentCtrl.js';
import './payables/ach/payments/controllers/inspectSelectedBatchesCtrl.js';
import './payables/ach/payments/controllers/nachaFileValidationErrorListCtrl.js';
import './payables/ach/payments/controllers/paymentDetailCtrl.js';
import './payables/ach/payments/controllers/recurringAchPaymentListCtrl.js';
import './payables/ach/payments/controllers/saveAsBatchModalCtrl.js';
import './payables/ach/payments/controllers/unInitiatePaymentCtrl.js';
import './payables/ach/payments/controllers/uploadPaymentCtrl.js';
import './payables/ach/recipients/controllers/masterRecipientModal.js';
import './payables/ach/recipients/controllers/masterRecipientsList.js';
import './payables/ach/recipients/controllers/recipientsActivityList.js';

import './payables/ach/payments/controllers/paymentRecipientsModalCtrl.js';
import './payables/ach/taxes/controllers/saveAsTemplateCtrl.js';
import './payables/ach/taxes/controllers/selectStatesCtrl.js';
import './payables/ach/taxes/controllers/taxCodesCtrl.js';
import './payables/ach/taxes/controllers/taxPaymentDetailCtrl.js';
import './payables/ach/taxes/controllers/userAccountsCtrl.js';

/** **ARP*** */
import './payables/arp/controllers/achExceptionFilterListCtrl.js';
import './payables/arp/controllers/achExceptionOpenItemsReviewModalCtrl.js';
import './payables/arp/controllers/achExceptionOpenItemsSaveConfirmationCtrl.js';
import './payables/arp/controllers/achExceptionReviewModalCtrl.js';
import './payables/arp/controllers/achExceptionsDecisionsActivityCtrl.js';
import './payables/arp/controllers/achExceptionsListCtrl.js';
import './payables/arp/controllers/achExceptionsOpenItemsCtrl.js';
import './payables/arp/controllers/arpAccountsCtrl.js';
import './payables/arp/controllers/arpExceptionCorrectionModalCtrl.js';
import './payables/arp/controllers/arpExceptionReviewModalCtrl.js';
import './payables/arp/controllers/arpExceptionsListCtrl.js';
import './payables/arp/controllers/arpFileErrorsCtrl.js';
import './payables/arp/controllers/arpOutputFileListCtrl.js';
import './payables/arp/controllers/arpUploadFormatListCtrl.js';
import './payables/arp/controllers/centrixSsoCtrl.js';
import './payables/arp/controllers/createArpCtrl.js';
import './payables/arp/controllers/issuedItemsActivityCtrl.js';
import './payables/arp/controllers/issuedItemSummaryCtrl.js';
import './payables/arp/controllers/issueItemAccountSummaryCtrl.js';
import './payables/arp/controllers/missingAccountsModal.js';
import './payables/arp/controllers/posPayFileMappingCtrl.js';
/** **Stop Payment*** */
import './payables/stp/controllers/changeStatusDialogCtrl.js';
import './payables/stp/controllers/createStpCtrl.js';
import './payables/stp/controllers/stpAccountsCtrl.js';
import './payables/stp/controllers/stpListCtrl.js';
/** **Admin*** */
import './admin/accountNickNames/controllers/accountNickNamesCtrl.js';
import './admin/ConfigureNotifications/configureNotificationsCtrl.js';
import './admin/ConfigureNotifications/requiredNotificationsCtrl.js';
import './admin/users/controllers/accountAccessCtrl.js';
import './admin/users/controllers/achUserEntitlementsCtrl.js';
import './admin/users/controllers/achUserLimitsCtrl.js';
import './admin/users/controllers/approveRejectCtrl.js';
import './admin/users/controllers/arpUserEntitlementsCtrl.js';
import './admin/users/controllers/changeUserStatusCtrl.js';
import './admin/users/controllers/copyUserModalCtrl.js';
import './admin/users/controllers/discardChangesCtrl.js';
import './admin/users/controllers/informationReportingEntitlementsCtrl.js';
import './admin/users/controllers/integratedServiceEntitlementsCtrl.js';
import './admin/users/controllers/internalTransferEntitlementsCtrl.js';
import './admin/users/controllers/internalTransferUserLimitsCtrl.js';
import './admin/users/controllers/ipRestrictCtrl.js';
import './admin/users/controllers/manageAchCompaniesCtrl.js';
import './admin/users/controllers/receivablesUserSettingsCtrl.js';
import './admin/users/controllers/ssoPosPayUserSettingsCtrl.js';
import './admin/users/controllers/stpEntitlementsCtrl.js';
import './admin/users/controllers/timeAccessCtrl.js';
import './admin/users/controllers/userInformationContainerCtrl.js';
import './admin/users/controllers/userInformationCtrl.js';
import './admin/users/controllers/userListCtrl.js';
import './admin/users/controllers/wireTransferDliUserLimitsCtrl.js';
import './admin/users/controllers/wireTransfersEntitlementsCtrl.js';
import './admin/users/controllers/wireTransferUserLimitsCtrl.js';

/** ******** Bill Pay ********** */
import './billPay/controllers/billPayCtrl.js';

/** ******** Receivables ********** */
import './receivables/controllers/remoteDepositCaptureRedirectCtrl.js';
import './receivables/controllers/remoteDepositCaptureSsoCtrl.js';

/** ***** Message Center ****** */
import './messageCenter/controllers/messageCenterArchiveModalCtrl.js';
import './messageCenter/controllers/messageCenterComposeModalCtrl.js';
import './messageCenter/controllers/messageCenterCtrl.js';
import './messageCenter/controllers/messageCenterSearchModalCtrl.js';
import './messageCenter/controllers/messageCenterViewModalCtrl.js';

/** * Challenge Point ** */
import './shared/security/controllers/verifyUserModal.js';

import './directives/module.js';
import './imports/filters.js';
import './services/ach/module.js';
import './services/common/module.js';
import './services/module.js';

import '@treasury/domain/dates/fi-date/fiDate.js';
import '@treasury/domain/wires/wireIso.service';
import './directives/formatting/currencyDir.js';
import './directives/validation/maxDir.js';
import './directives/validation/minDir.js';
import './payables/ach/recipients/services/recipientValidatorSrvc.js';
import './services/accountSrvc.js';
import './services/accountTransactionsSrvc.js';
import './services/ach/achMasterRecipientService.js';
import './services/achBankSrvc.js';
import './services/achBatchImportsSrvc.js';
import './services/achBatchSrvc.js';
import './services/achCompaniesSrvc.js';
import './services/achFileActivitySrvc.js';
import './services/achPaymentsSrvc.js';
import './services/achRecipientsSrvc.js';
import './services/achSettingsSrvc.js';
import './services/arpService.js';
import './services/bankSrvc.js';
import './services/bdiEStatementsSrvc.js';
import './services/beneficiariesSrvc.js';
import './services/billPayService.js';
import './services/centrixSsoSrvc.js';
import './services/checkImageSrvc.js';
import './services/common/activityService.js';
import './services/common/cacheSrvc.js';
import './services/common/downloadSrvc.js';
import './services/common/modalSrvc.js';
import './services/common/pagePushSrvc.js';
import './services/common/scopeSrvc.js';
import './services/common/spinnerSrvc.js';
import './services/companyAccountsSrvc.js';
import './services/countriesSrvc.js';
import './services/currenciesSrvc.js';
import './services/dashboardSrvc.js';
import './services/dateFilterSrvc.js';
import './services/electronicDocumentsSrvc.js';
import './services/entitlementsSrvc.js';
import './services/frequencySrvc.js';
import './services/generateReportSrvc.js';
import './services/holidaysSrvc.js';
import './services/importLayoutSrvc.js';
import './services/informationReportsSrvc.js';
import './services/internalTransfersSrvc.js';
import './services/messagingCenterSrvc.js';
import './services/mockDataSrvc.js';
import './services/navigationSrvc.js';
import './services/notificationConfigurationSrvc.js';
import './services/notificationsSrvc.js';
import './services/paymentPendingApprovalProductsService.js';
import './services/printSrvc.js';
import './services/remoteDepositCaptureSsoSrvc.js';
import './services/researchTransactionSrvc.js';
import './services/searchFilterSrvc.js';
import './services/SecCodesSrvc.js';
import './services/securitySrvc.js';
import './services/stateChangeSrvc.js';
import './services/statesSrvc.js';
import './services/stpService.js';
import './services/taxesSrvc.js';
import './services/termsAndConditionsSrvc.js';
import './services/tranCodeGrpSrvc.js';
import './services/usersSrvc.js';
import './services/utilitySrvc.js';
import './services/wausauStatementsSrvc.js';
import './services/wireCompaniesSrvc.js';
import './services/wireService.js';

import '@treasury/omega/components/feature-flags/feature-flag-button';
import '../src/dev-sandbox/dev-sandbox.js';

getChannelModule();
